"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postalCode = void 0;
var DEFAULT_MIN_POSTAL_CODE_LENGTH = 3;
var ALPHANUM = new RegExp(/^[a-z0-9]+$/i);
function verification(isValid, isPotentiallyValid) {
  return {
    isValid: isValid,
    isPotentiallyValid: isPotentiallyValid
  };
}
function postalCode(value, options) {
  if (options === void 0) {
    options = {};
  }
  var minLength = options.minLength || DEFAULT_MIN_POSTAL_CODE_LENGTH;
  if (typeof value !== "string") {
    return verification(false, false);
  } else if (value.length < minLength) {
    return verification(false, true);
  } else if (!ALPHANUM.test(value.trim().slice(0, minLength))) {
    return verification(false, true);
  }
  return verification(true, true);
}
exports.postalCode = postalCode;