"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cardNumber = void 0;
var luhn10 = require("./luhn-10");
var getCardTypes = require("credit-card-type");
function verification(card, isPotentiallyValid, isValid) {
  return {
    card: card,
    isPotentiallyValid: isPotentiallyValid,
    isValid: isValid
  };
}
function cardNumber(value, options) {
  if (options === void 0) {
    options = {};
  }
  var isPotentiallyValid, isValid, maxLength;
  if (typeof value !== "string" && typeof value !== "number") {
    return verification(null, false, false);
  }
  var testCardValue = String(value).replace(/-|\s/g, "");
  if (!/^\d*$/.test(testCardValue)) {
    return verification(null, false, false);
  }
  var potentialTypes = getCardTypes(testCardValue);
  if (potentialTypes.length === 0) {
    return verification(null, false, false);
  } else if (potentialTypes.length !== 1) {
    return verification(null, true, false);
  }
  var cardType = potentialTypes[0];
  if (options.maxLength && testCardValue.length > options.maxLength) {
    return verification(cardType, false, false);
  }
  if (options.skipLuhnValidation === true || cardType.type === getCardTypes.types.UNIONPAY && options.luhnValidateUnionPay !== true) {
    isValid = true;
  } else {
    isValid = luhn10(testCardValue);
  }
  maxLength = Math.max.apply(null, cardType.lengths);
  if (options.maxLength) {
    maxLength = Math.min(options.maxLength, maxLength);
  }
  for (var i = 0; i < cardType.lengths.length; i++) {
    if (cardType.lengths[i] === testCardValue.length) {
      isPotentiallyValid = testCardValue.length < maxLength || isValid;
      return verification(cardType, isPotentiallyValid, isValid);
    }
  }
  return verification(cardType, testCardValue.length < maxLength, false);
}
exports.cardNumber = cardNumber;