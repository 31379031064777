"use strict";

/*
 * Adapted from https://github.com/polvo-labs/card-type/blob/aaab11f80fa1939bccc8f24905a06ae3cd864356/src/cardType.js#L37-L42
 * */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matches = void 0;
function matchesRange(cardNumber, min, max) {
  var maxLengthToCheck = String(min).length;
  var substr = cardNumber.substr(0, maxLengthToCheck);
  var integerRepresentationOfCardNumber = parseInt(substr, 10);
  min = parseInt(String(min).substr(0, substr.length), 10);
  max = parseInt(String(max).substr(0, substr.length), 10);
  return integerRepresentationOfCardNumber >= min && integerRepresentationOfCardNumber <= max;
}
function matchesPattern(cardNumber, pattern) {
  pattern = String(pattern);
  return pattern.substring(0, cardNumber.length) === cardNumber.substring(0, pattern.length);
}
function matches(cardNumber, pattern) {
  if (Array.isArray(pattern)) {
    return matchesRange(cardNumber, pattern[0], pattern[1]);
  }
  return matchesPattern(cardNumber, pattern);
}
exports.matches = matches;