"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cardholderName = void 0;
var CARD_NUMBER_REGEX = /^[\d\s-]*$/;
var MAX_LENGTH = 255;
function verification(isValid, isPotentiallyValid) {
  return {
    isValid: isValid,
    isPotentiallyValid: isPotentiallyValid
  };
}
function cardholderName(value) {
  if (typeof value !== "string") {
    return verification(false, false);
  }
  if (value.length === 0) {
    return verification(false, true);
  }
  if (value.length > MAX_LENGTH) {
    return verification(false, false);
  }
  if (CARD_NUMBER_REGEX.test(value)) {
    return verification(false, true);
  }
  return verification(true, true);
}
exports.cardholderName = cardholderName;