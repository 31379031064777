/* tslint:disable */
/* eslint-disable */
/**
 * Payments API
 * # Introduction Manages onboarding of vendors and processing of payments  # Authentication The Payments API uses the OAuth2 client credentials grant to authorize service-to-service requests on the backend.  The caller should have only one application client that requests tokens for different audiences. Each API or audience that app client wishes to request tokens for requires an authorization in shared services IaC for Auth0.  If your application has not received Auth0 client credentials please refer to Architecture  Scopes are configured on client setup and all tokens requested by the client will have all scopes the client is entitled to.  The Token URL will change per environment.  - Dev - `https://dev.api.linqengineering.com/accounts/oauth/token`  - UAT - `https://uat.api.linqengineering.com/accounts/oauth/token`  - Production - `https://api.linqengineering.com/accounts/oauth/token`  In addition to your client credentials you also need to supply an audience value based on the environment. <br /> Valid Audiences include:      - Dev - `https://dev.api.linqengineering.com/payments`    - UAT - `https://uat.api.linqengineering.com/payments`    - Production - `https://api.linqengineering.com/payments`  Example Postman request to accounts API token endpoint:  ``` POST https://{{apiDomain}}.linqengineering.com/accounts/oauth/token ... raw -> JSON Body  {   \"clientId\": \"{{clientId}}\",   \"clientSecret\": \"{{clientSecret}}\",   \"audience\": \"https://{{apiDomain}}.linqengineering.com/payments\" } ```  See the Shared Services Postman collection for additional working examples. The returned access token should be added as a `Bearer` token authorization header.  API requests without an authorization header will fail.   <SecurityDefinitions />
 *
 * The version of the OpenAPI document: 0.0.33
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from './base';
export const CreateTransactionRequestTransactionTypeEnum = {
  CardAuth: 'card_auth',
  CardSale: 'card_sale',
  CardVoid: 'card_void',
  EcheckSale: 'echeck_sale',
  CardRefund: 'card_refund',
  EcheckRefund: 'echeck_refund'
};
export const CreateTransactionRequestCardOnFileTypeEnum = {
  Single: 'single',
  Scheduled: 'scheduled',
  Unscheduled: 'unscheduled'
};
export const CreateTransactionRequestOriginEnum = {
  CreditCardTerminal: 'credit_card_terminal',
  EcommerceSystem: 'ecommerce_system',
  RecurringTransaction: 'recurring_transaction'
};
export const PaymentDeviceInfoStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
};
export const PaymentMethodBankAccountTypeEnum = {
  Checking: 'checking',
  Savings: 'savings',
  CorporateChecking: 'corporate_checking',
  CorporateSavings: 'corporate_savings'
};
export const PaymentMethodCardTypeEnum = {
  AmericanExpress: 'american_express',
  Visa: 'visa',
  Mastercard: 'mastercard',
  DinersClub: 'diners_club',
  Discover: 'discover'
};
export const PostGuestCheckoutInvoicePaymentMethodEnum = {
  Card: 'card',
  Echeck: 'echeck'
};
export const PostPaymentDeviceResponseStatusEnum = {
  Active: 'active'
};
export const PostPaymentDeviceSaleResponseStatusEnum = {
  Approved: 'approved',
  Failed: 'failed'
};
export const PostPaymentDeviceSaleResponsePaymentTypeEnum = {
  None: 'none',
  Credit: 'credit',
  Debit: 'debit',
  Gift: 'gift',
  Ebt: 'ebt',
  Unknown: 'unknown'
};
export const PostTokenACHPaymentTypeEnum = {
  Echeck: 'echeck'
};
export const PostTokenACHPaymentMethodEnum = {
  Checking: 'checking',
  Savings: 'savings',
  CorporateChecking: 'corporate_checking',
  CorporateSavings: 'corporate_savings'
};
export const PostTokenCardPaymentTypeEnum = {
  Card: 'card'
};
export const TransactionTransactionTypeEnum = {
  CardAuth: 'card_auth',
  CardSale: 'card_sale',
  CardVoid: 'card_void',
  EcheckSale: 'echeck_sale',
  CardRefund: 'card_refund',
  EcheckRefund: 'echeck_refund'
};
export const TransactionStatusEnum = {
  Pending: 'pending',
  Processing: 'processing',
  Approved: 'approved',
  Declined: 'declined',
  Captured: 'captured',
  Settled: 'settled',
  Failed: 'failed',
  Cancelled: 'cancelled',
  Voided: 'voided',
  Error: 'error',
  Unknown: 'unknown'
};
export const TransactionCardOnFileTypeEnum = {
  Single: 'single',
  Scheduled: 'scheduled',
  Unscheduled: 'unscheduled'
};
export const TransactionOriginEnum = {
  CreditCardTerminal: 'credit_card_terminal',
  EcommerceSystem: 'ecommerce_system',
  RecurringTransaction: 'recurring_transaction'
};
export const TransactionPaymentMethodBankAccountTypeEnum = {
  Checking: 'checking',
  Savings: 'savings',
  CorporateChecking: 'corporate_checking',
  CorporateSavings: 'corporate_savings'
};
export const TransactionPaymentMethodCardTypeEnum = {
  AmericanExpress: 'american_express',
  Visa: 'visa',
  Mastercard: 'mastercard',
  DinersClub: 'diners_club',
  Discover: 'discover'
};
export const TransactionSummaryInfoTransactionTypeEnum = {
  CardAuth: 'card_auth',
  CardSale: 'card_sale',
  CardVoid: 'card_void',
  EcheckSale: 'echeck_sale',
  CardRefund: 'card_refund',
  EcheckRefund: 'echeck_refund'
};
export const TransactionSummaryInfoStatusEnum = {
  Pending: 'pending',
  Processing: 'processing',
  Approved: 'approved',
  Declined: 'declined',
  Captured: 'captured',
  Settled: 'settled',
  Failed: 'failed',
  Voided: 'voided',
  Cancelled: 'cancelled',
  Error: 'error',
  Unknown: 'unknown'
};
export const TransactionSummaryInfoOriginEnum = {
  CreditCardTerminal: 'credit_card_terminal',
  EcommerceSystem: 'ecommerce_system',
  RecurringTransaction: 'recurring_transaction'
};
/**
 * BatchApi - axios parameter creator
 * @export
 */
export const BatchApiAxiosParamCreator = function (configuration) {
  return {
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions: (batchId, page, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'batchId' is not null or undefined
      assertParamExists('getBatchTransactions', 'batchId', batchId);
      const localVarPath = `/batches/{batchId}/transactions`.replace(`{${"batchId"}}`, encodeURIComponent(String(batchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * BatchApi - functional programming interface
 * @export
 */
export const BatchApiFp = function (configuration) {
  const localVarAxiosParamCreator = BatchApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions(batchId, page, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getBatchTransactions(batchId, page, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BatchApi.getBatchTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * BatchApi - factory interface
 * @export
 */
export const BatchApiFactory = function (configuration, basePath, axios) {
  const localVarFp = BatchApiFp(configuration);
  return {
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions(batchId, page, options) {
      return localVarFp.getBatchTransactions(batchId, page, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * BatchApi - object-oriented interface
 * @export
 * @class BatchApi
 * @extends {BaseAPI}
 */
export class BatchApi extends BaseAPI {
  /**
   * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
   * @summary Get transactions for a given batchId
   * @param {string} batchId
   * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchApi
   */
  getBatchTransactions(batchId, page, options) {
    return BatchApiFp(this.configuration).getBatchTransactions(batchId, page, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * DisbursementsApi - axios parameter creator
 * @export
 */
export const DisbursementsApiAxiosParamCreator = function (configuration) {
  return {
    /**
     * Disbursements
     * @summary Disbursements
     * @param {string} [merchantId]
     * @param {string} [transactionId]
     * @param {number} [isFee]
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page] page number (1-based)
     * @param {number} [limit] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisbursements: (merchantId, transactionId, isFee, from, to, page, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/disbursements`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      if (merchantId !== undefined) {
        localVarQueryParameter['merchantId'] = merchantId;
      }
      if (transactionId !== undefined) {
        localVarQueryParameter['transactionId'] = transactionId;
      }
      if (isFee !== undefined) {
        localVarQueryParameter['isFee'] = isFee;
      }
      if (from !== undefined) {
        localVarQueryParameter['from'] = from instanceof Date ? from.toISOString().substring(0, 10) : from;
      }
      if (to !== undefined) {
        localVarQueryParameter['to'] = to instanceof Date ? to.toISOString().substring(0, 10) : to;
      }
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * DisbursementsApi - functional programming interface
 * @export
 */
export const DisbursementsApiFp = function (configuration) {
  const localVarAxiosParamCreator = DisbursementsApiAxiosParamCreator(configuration);
  return {
    /**
     * Disbursements
     * @summary Disbursements
     * @param {string} [merchantId]
     * @param {string} [transactionId]
     * @param {number} [isFee]
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page] page number (1-based)
     * @param {number} [limit] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DisbursementsApi.getDisbursements']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * DisbursementsApi - factory interface
 * @export
 */
export const DisbursementsApiFactory = function (configuration, basePath, axios) {
  const localVarFp = DisbursementsApiFp(configuration);
  return {
    /**
     * Disbursements
     * @summary Disbursements
     * @param {string} [merchantId]
     * @param {string} [transactionId]
     * @param {number} [isFee]
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page] page number (1-based)
     * @param {number} [limit] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options) {
      return localVarFp.getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * DisbursementsApi - object-oriented interface
 * @export
 * @class DisbursementsApi
 * @extends {BaseAPI}
 */
export class DisbursementsApi extends BaseAPI {
  /**
   * Disbursements
   * @summary Disbursements
   * @param {string} [merchantId]
   * @param {string} [transactionId]
   * @param {number} [isFee]
   * @param {string} [from]
   * @param {string} [to]
   * @param {number} [page] page number (1-based)
   * @param {number} [limit] number of items per page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DisbursementsApi
   */
  getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options) {
    return DisbursementsApiFp(this.configuration).getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration) {
  return {
    /**
     * Return the OpenAPI document that describes this API.
     * @summary Return API definition document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiDoc: (options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/document`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function (configuration) {
  const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration);
  return {
    /**
     * Return the OpenAPI document that describes this API.
     * @summary Return API definition document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiDoc(options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getApiDoc(options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DocumentApi.getApiDoc']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration, basePath, axios) {
  const localVarFp = DocumentApiFp(configuration);
  return {
    /**
     * Return the OpenAPI document that describes this API.
     * @summary Return API definition document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiDoc(options) {
      return localVarFp.getApiDoc(options).then(request => request(axios, basePath));
    }
  };
};
/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
  /**
   * Return the OpenAPI document that describes this API.
   * @summary Return API definition document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  getApiDoc(options) {
    return DocumentApiFp(this.configuration).getApiDoc(options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument: (documentId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('postDocument', 'documentId', documentId);
      const localVarPath = `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      const localVarFormParams = new (configuration && configuration.formDataCtor || FormData)();
      if (file !== undefined) {
        localVarFormParams.append('file', file);
      }
      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = localVarFormParams;
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function (configuration) {
  const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument(documentId, file, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postDocument(documentId, file, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DocumentsApi.postDocument']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration, basePath, axios) {
  const localVarFp = DocumentsApiFp(configuration);
  return {
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument(documentId, file, options) {
      return localVarFp.postDocument(documentId, file, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
  /**
   *
   * @summary Upload a document
   * @param {string} documentId
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  postDocument(documentId, file, options) {
    return DocumentsApiFp(this.configuration).postDocument(documentId, file, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * FeesApi - axios parameter creator
 * @export
 */
export const FeesApiAxiosParamCreator = function (configuration) {
  return {
    /**
     * Get fee
     * @summary Get fee
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFee: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getFee', 'id', id);
      const localVarPath = `/fees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Search fees
     * @summary Search fees
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFees: (page, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/fees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Create fee
     * @summary Create fee
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFee: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('postFee', 'body', body);
      const localVarPath = `/fees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * FeesApi - functional programming interface
 * @export
 */
export const FeesApiFp = function (configuration) {
  const localVarAxiosParamCreator = FeesApiAxiosParamCreator(configuration);
  return {
    /**
     * Get fee
     * @summary Get fee
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFee(id, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getFee(id, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['FeesApi.getFee']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Search fees
     * @summary Search fees
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFees(page, limit, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getFees(page, limit, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['FeesApi.getFees']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Create fee
     * @summary Create fee
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFee(body, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postFee(body, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['FeesApi.postFee']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * FeesApi - factory interface
 * @export
 */
export const FeesApiFactory = function (configuration, basePath, axios) {
  const localVarFp = FeesApiFp(configuration);
  return {
    /**
     * Get fee
     * @summary Get fee
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFee(id, options) {
      return localVarFp.getFee(id, options).then(request => request(axios, basePath));
    },
    /**
     * Search fees
     * @summary Search fees
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFees(page, limit, options) {
      return localVarFp.getFees(page, limit, options).then(request => request(axios, basePath));
    },
    /**
     * Create fee
     * @summary Create fee
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFee(body, options) {
      return localVarFp.postFee(body, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * FeesApi - object-oriented interface
 * @export
 * @class FeesApi
 * @extends {BaseAPI}
 */
export class FeesApi extends BaseAPI {
  /**
   * Get fee
   * @summary Get fee
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeesApi
   */
  getFee(id, options) {
    return FeesApiFp(this.configuration).getFee(id, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Search fees
   * @summary Search fees
   * @param {number} [page]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeesApi
   */
  getFees(page, limit, options) {
    return FeesApiFp(this.configuration).getFees(page, limit, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Create fee
   * @summary Create fee
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeesApi
   */
  postFee(body, options) {
    return FeesApiFp(this.configuration).postFee(body, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * GuestApi - axios parameter creator
 * @export
 */
export const GuestApiAxiosParamCreator = function (configuration) {
  return {
    /**
     * Create a transaction for an invoice.
     * @summary Create a transaction for an invoice
     * @param {PostGuestCheckoutInvoice} [postGuestCheckoutInvoice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGuestCheckoutInvoice: (postGuestCheckoutInvoice, options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/guest/checkout/invoice`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postGuestCheckoutInvoice, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * GuestApi - functional programming interface
 * @export
 */
export const GuestApiFp = function (configuration) {
  const localVarAxiosParamCreator = GuestApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a transaction for an invoice.
     * @summary Create a transaction for an invoice
     * @param {PostGuestCheckoutInvoice} [postGuestCheckoutInvoice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGuestCheckoutInvoice(postGuestCheckoutInvoice, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postGuestCheckoutInvoice(postGuestCheckoutInvoice, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['GuestApi.postGuestCheckoutInvoice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * GuestApi - factory interface
 * @export
 */
export const GuestApiFactory = function (configuration, basePath, axios) {
  const localVarFp = GuestApiFp(configuration);
  return {
    /**
     * Create a transaction for an invoice.
     * @summary Create a transaction for an invoice
     * @param {PostGuestCheckoutInvoice} [postGuestCheckoutInvoice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGuestCheckoutInvoice(postGuestCheckoutInvoice, options) {
      return localVarFp.postGuestCheckoutInvoice(postGuestCheckoutInvoice, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * GuestApi - object-oriented interface
 * @export
 * @class GuestApi
 * @extends {BaseAPI}
 */
export class GuestApi extends BaseAPI {
  /**
   * Create a transaction for an invoice.
   * @summary Create a transaction for an invoice
   * @param {PostGuestCheckoutInvoice} [postGuestCheckoutInvoice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestApi
   */
  postGuestCheckoutInvoice(postGuestCheckoutInvoice, options) {
    return GuestApiFp(this.configuration).postGuestCheckoutInvoice(postGuestCheckoutInvoice, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration) {
  return {
    /**
     * The Payrix integration will post to this endpoint for configured web alerts.
     * @summary Webhook callback endpoint for Payrix
     * @param {string} eventType Payrix webhook event type - used to dispatch on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPayrixCallback: (eventType, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'eventType' is not null or undefined
      assertParamExists('postPayrixCallback', 'eventType', eventType);
      const localVarPath = `/payrix/callback/{eventType}`.replace(`{${"eventType"}}`, encodeURIComponent(String(eventType)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function (configuration) {
  const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration);
  return {
    /**
     * The Payrix integration will post to this endpoint for configured web alerts.
     * @summary Webhook callback endpoint for Payrix
     * @param {string} eventType Payrix webhook event type - used to dispatch on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPayrixCallback(eventType, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPayrixCallback(eventType, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['InternalApi.postPayrixCallback']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration, basePath, axios) {
  const localVarFp = InternalApiFp(configuration);
  return {
    /**
     * The Payrix integration will post to this endpoint for configured web alerts.
     * @summary Webhook callback endpoint for Payrix
     * @param {string} eventType Payrix webhook event type - used to dispatch on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPayrixCallback(eventType, options) {
      return localVarFp.postPayrixCallback(eventType, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
  /**
   * The Payrix integration will post to this endpoint for configured web alerts.
   * @summary Webhook callback endpoint for Payrix
   * @param {string} eventType Payrix webhook event type - used to dispatch on
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApi
   */
  postPayrixCallback(eventType, options) {
    return InternalApiFp(this.configuration).postPayrixCallback(eventType, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * MerchantsApi - axios parameter creator
 * @export
 */
export const MerchantsApiAxiosParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary Unsubscribe from this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMerchantSubscription: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('deleteMerchantSubscription', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/subscriptions`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'DELETE'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice: (merchantId, deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('deletePaymentDevice', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('deletePaymentDevice', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'DELETE'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Get merchant object
     * @summary Get merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('getMerchant', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Get merchants
     * @summary Get merchants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchants: (options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/merchants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice: (merchantId, deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('getPaymentDevice', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('getPaymentDevice', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('getPaymentDevices', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument: (documentId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('postDocument', 'documentId', documentId);
      const localVarPath = `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      const localVarFormParams = new (configuration && configuration.formDataCtor || FormData)();
      if (file !== undefined) {
        localVarFormParams.append('file', file);
      }
      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = localVarFormParams;
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Create a merchant
     * @param {PostMerchantRequest} postMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchant: (postMerchantRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'postMerchantRequest' is not null or undefined
      assertParamExists('postMerchant', 'postMerchantRequest', postMerchantRequest);
      const localVarPath = `/merchants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postMerchantRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Subscribe to this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchantSubscription: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('postMerchantSubscription', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/subscriptions`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice: (merchantId, postPaymentDeviceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('postPaymentDevice', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postPaymentDeviceRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale: (merchantId, deviceId, postPaymentDeviceSaleRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('postPaymentDeviceSale', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('postPaymentDeviceSale', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}/sale`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postPaymentDeviceSaleRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @param {PostPlaidLinkRequest} postPlaidLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlaidLink: (postPlaidLinkRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'postPlaidLinkRequest' is not null or undefined
      assertParamExists('postPlaidLink', 'postPlaidLinkRequest', postPlaidLinkRequest);
      const localVarPath = `/payrix/plaid/link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postPlaidLinkRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * MerchantsApi - functional programming interface
 * @export
 */
export const MerchantsApiFp = function (configuration) {
  const localVarAxiosParamCreator = MerchantsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Unsubscribe from this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMerchantSubscription(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteMerchantSubscription(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.deleteMerchantSubscription']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice(merchantId, deviceId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePaymentDevice(merchantId, deviceId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.deletePaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Get merchant object
     * @summary Get merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getMerchant(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.getMerchant']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Get merchants
     * @summary Get merchants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchants(options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getMerchants(options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.getMerchants']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice(merchantId, deviceId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getPaymentDevice(merchantId, deviceId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.getPaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getPaymentDevices(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.getPaymentDevices']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument(documentId, file, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postDocument(documentId, file, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.postDocument']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Create a merchant
     * @param {PostMerchantRequest} postMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchant(postMerchantRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postMerchant(postMerchantRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.postMerchant']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Subscribe to this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchantSubscription(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postMerchantSubscription(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.postMerchantSubscription']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPaymentDevice(merchantId, postPaymentDeviceRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.postPaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.postPaymentDeviceSale']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @param {PostPlaidLinkRequest} postPlaidLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlaidLink(postPlaidLinkRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPlaidLink(postPlaidLinkRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MerchantsApi.postPlaidLink']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * MerchantsApi - factory interface
 * @export
 */
export const MerchantsApiFactory = function (configuration, basePath, axios) {
  const localVarFp = MerchantsApiFp(configuration);
  return {
    /**
     *
     * @summary Unsubscribe from this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMerchantSubscription(merchantId, options) {
      return localVarFp.deleteMerchantSubscription(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice(merchantId, deviceId, options) {
      return localVarFp.deletePaymentDevice(merchantId, deviceId, options).then(request => request(axios, basePath));
    },
    /**
     * Get merchant object
     * @summary Get merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant(merchantId, options) {
      return localVarFp.getMerchant(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     * Get merchants
     * @summary Get merchants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchants(options) {
      return localVarFp.getMerchants(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice(merchantId, deviceId, options) {
      return localVarFp.getPaymentDevice(merchantId, deviceId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices(merchantId, options) {
      return localVarFp.getPaymentDevices(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument(documentId, file, options) {
      return localVarFp.postDocument(documentId, file, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create a merchant
     * @param {PostMerchantRequest} postMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchant(postMerchantRequest, options) {
      return localVarFp.postMerchant(postMerchantRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Subscribe to this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchantSubscription(merchantId, options) {
      return localVarFp.postMerchantSubscription(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
      return localVarFp.postPaymentDevice(merchantId, postPaymentDeviceRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
      return localVarFp.postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {PostPlaidLinkRequest} postPlaidLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlaidLink(postPlaidLinkRequest, options) {
      return localVarFp.postPlaidLink(postPlaidLinkRequest, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * MerchantsApi - object-oriented interface
 * @export
 * @class MerchantsApi
 * @extends {BaseAPI}
 */
export class MerchantsApi extends BaseAPI {
  /**
   *
   * @summary Unsubscribe from this merchant for callback events
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  deleteMerchantSubscription(merchantId, options) {
    return MerchantsApiFp(this.configuration).deleteMerchantSubscription(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Deregister a payment device for the given merchant
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  deletePaymentDevice(merchantId, deviceId, options) {
    return MerchantsApiFp(this.configuration).deletePaymentDevice(merchantId, deviceId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Get merchant object
   * @summary Get merchant
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  getMerchant(merchantId, options) {
    return MerchantsApiFp(this.configuration).getMerchant(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Get merchants
   * @summary Get merchants
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  getMerchants(options) {
    return MerchantsApiFp(this.configuration).getMerchants(options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get payment device information
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  getPaymentDevice(merchantId, deviceId, options) {
    return MerchantsApiFp(this.configuration).getPaymentDevice(merchantId, deviceId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary List all active payment devices for the given merchant
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  getPaymentDevices(merchantId, options) {
    return MerchantsApiFp(this.configuration).getPaymentDevices(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Upload a document
   * @param {string} documentId
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  postDocument(documentId, file, options) {
    return MerchantsApiFp(this.configuration).postDocument(documentId, file, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Create a merchant
   * @param {PostMerchantRequest} postMerchantRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  postMerchant(postMerchantRequest, options) {
    return MerchantsApiFp(this.configuration).postMerchant(postMerchantRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Subscribe to this merchant for callback events
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  postMerchantSubscription(merchantId, options) {
    return MerchantsApiFp(this.configuration).postMerchantSubscription(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Register a payment device for the given merchant
   * @param {string} merchantId
   * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
    return MerchantsApiFp(this.configuration).postPaymentDevice(merchantId, postPaymentDeviceRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Post sale transaction via the given payment device
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
    return MerchantsApiFp(this.configuration).postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {PostPlaidLinkRequest} postPlaidLinkRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MerchantsApi
   */
  postPlaidLink(postPlaidLinkRequest, options) {
    return MerchantsApiFp(this.configuration).postPlaidLink(postPlaidLinkRequest, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * PayApi - axios parameter creator
 * @export
 */
export const PayApiAxiosParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary Unsubscribe from this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMerchantSubscription: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('deleteMerchantSubscription', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/subscriptions`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'DELETE'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice: (merchantId, deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('deletePaymentDevice', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('deletePaymentDevice', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'DELETE'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Clients can cancel an approved transaction. This must be done before the batch closes which would then mark the transaction as captured. Note, the transaction is never actually deleted, its moved into the cancel state.
     * @summary Cancel an approved transaction
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransaction: (transactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('deleteTransaction', 'transactionId', transactionId);
      const localVarPath = `/transactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'DELETE'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Return app configuration for the current app client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppConfig: (options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions: (batchId, page, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'batchId' is not null or undefined
      assertParamExists('getBatchTransactions', 'batchId', batchId);
      const localVarPath = `/batches/{batchId}/transactions`.replace(`{${"batchId"}}`, encodeURIComponent(String(batchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Disbursements
     * @summary Disbursements
     * @param {string} [merchantId]
     * @param {string} [transactionId]
     * @param {number} [isFee]
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page] page number (1-based)
     * @param {number} [limit] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisbursements: (merchantId, transactionId, isFee, from, to, page, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/disbursements`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      if (merchantId !== undefined) {
        localVarQueryParameter['merchantId'] = merchantId;
      }
      if (transactionId !== undefined) {
        localVarQueryParameter['transactionId'] = transactionId;
      }
      if (isFee !== undefined) {
        localVarQueryParameter['isFee'] = isFee;
      }
      if (from !== undefined) {
        localVarQueryParameter['from'] = from instanceof Date ? from.toISOString().substring(0, 10) : from;
      }
      if (to !== undefined) {
        localVarQueryParameter['to'] = to instanceof Date ? to.toISOString().substring(0, 10) : to;
      }
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Get fee
     * @summary Get fee
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFee: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getFee', 'id', id);
      const localVarPath = `/fees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Search fees
     * @summary Search fees
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFees: (page, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/fees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Get merchant object
     * @summary Get merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('getMerchant', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Get merchants
     * @summary Get merchants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchants: (options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/merchants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice: (merchantId, deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('getPaymentDevice', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('getPaymentDevice', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('getPaymentDevices', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Heartbeat check for payrix
     * @summary Heartbeat check for payrix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayrixHeartbeat: (options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/payrix/heartbeat`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Get transaction by transaction ID
     * @summary Get transaction
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction: (transactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('getTransaction', 'transactionId', transactionId);
      const localVarPath = `/transactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument: (documentId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('postDocument', 'documentId', documentId);
      const localVarPath = `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      const localVarFormParams = new (configuration && configuration.formDataCtor || FormData)();
      if (file !== undefined) {
        localVarFormParams.append('file', file);
      }
      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = localVarFormParams;
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Create fee
     * @summary Create fee
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFee: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('postFee', 'body', body);
      const localVarPath = `/fees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Create a transaction for an invoice.
     * @summary Create a transaction for an invoice
     * @param {PostGuestCheckoutInvoice} [postGuestCheckoutInvoice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGuestCheckoutInvoice: (postGuestCheckoutInvoice, options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/guest/checkout/invoice`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postGuestCheckoutInvoice, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Create a merchant
     * @param {PostMerchantRequest} postMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchant: (postMerchantRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'postMerchantRequest' is not null or undefined
      assertParamExists('postMerchant', 'postMerchantRequest', postMerchantRequest);
      const localVarPath = `/merchants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postMerchantRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Subscribe to this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchantSubscription: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('postMerchantSubscription', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/subscriptions`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice: (merchantId, postPaymentDeviceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('postPaymentDevice', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postPaymentDeviceRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale: (merchantId, deviceId, postPaymentDeviceSaleRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('postPaymentDeviceSale', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('postPaymentDeviceSale', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}/sale`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postPaymentDeviceSaleRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @param {PostPlaidLinkRequest} postPlaidLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlaidLink: (postPlaidLinkRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'postPlaidLinkRequest' is not null or undefined
      assertParamExists('postPlaidLink', 'postPlaidLinkRequest', postPlaidLinkRequest);
      const localVarPath = `/payrix/plaid/link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postPlaidLinkRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * When performing recurring payments use this operation to trade user supplied payment information for a token which can be used to perform automated payments.
     * @summary Create a token for a recurring payment.
     * @param {PostTokenRequest} [postTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postToken: (postTokenRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postTokenRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     * Create a transaction for payment, capture, refund, or void.
     * @summary Create a transaction for payment, capture, refund, or void.
     * @param {CreateTransactionRequest} [createTransactionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTransaction: (createTransactionRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      const localVarPath = `/transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(createTransactionRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Update app configuration for the current app client
     * @param {AppConfig} appConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAppConfig: (appConfig, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'appConfig' is not null or undefined
      assertParamExists('putAppConfig', 'appConfig', appConfig);
      const localVarPath = `/config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'PUT'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(appConfig, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * PayApi - functional programming interface
 * @export
 */
export const PayApiFp = function (configuration) {
  const localVarAxiosParamCreator = PayApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Unsubscribe from this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMerchantSubscription(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteMerchantSubscription(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.deleteMerchantSubscription']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice(merchantId, deviceId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePaymentDevice(merchantId, deviceId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.deletePaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Clients can cancel an approved transaction. This must be done before the batch closes which would then mark the transaction as captured. Note, the transaction is never actually deleted, its moved into the cancel state.
     * @summary Cancel an approved transaction
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransaction(transactionId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteTransaction(transactionId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.deleteTransaction']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Return app configuration for the current app client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppConfig(options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getAppConfig(options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getAppConfig']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions(batchId, page, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getBatchTransactions(batchId, page, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getBatchTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Disbursements
     * @summary Disbursements
     * @param {string} [merchantId]
     * @param {string} [transactionId]
     * @param {number} [isFee]
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page] page number (1-based)
     * @param {number} [limit] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getDisbursements']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Get fee
     * @summary Get fee
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFee(id, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getFee(id, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getFee']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Search fees
     * @summary Search fees
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFees(page, limit, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getFees(page, limit, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getFees']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Get merchant object
     * @summary Get merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getMerchant(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getMerchant']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Get merchants
     * @summary Get merchants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchants(options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getMerchants(options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getMerchants']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice(merchantId, deviceId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getPaymentDevice(merchantId, deviceId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getPaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getPaymentDevices(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getPaymentDevices']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Heartbeat check for payrix
     * @summary Heartbeat check for payrix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayrixHeartbeat(options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getPayrixHeartbeat(options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getPayrixHeartbeat']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Get transaction by transaction ID
     * @summary Get transaction
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction(transactionId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getTransaction(transactionId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.getTransaction']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument(documentId, file, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postDocument(documentId, file, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postDocument']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Create fee
     * @summary Create fee
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFee(body, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postFee(body, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postFee']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Create a transaction for an invoice.
     * @summary Create a transaction for an invoice
     * @param {PostGuestCheckoutInvoice} [postGuestCheckoutInvoice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGuestCheckoutInvoice(postGuestCheckoutInvoice, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postGuestCheckoutInvoice(postGuestCheckoutInvoice, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postGuestCheckoutInvoice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Create a merchant
     * @param {PostMerchantRequest} postMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchant(postMerchantRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postMerchant(postMerchantRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postMerchant']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Subscribe to this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchantSubscription(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postMerchantSubscription(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postMerchantSubscription']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPaymentDevice(merchantId, postPaymentDeviceRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postPaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postPaymentDeviceSale']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @param {PostPlaidLinkRequest} postPlaidLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlaidLink(postPlaidLinkRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPlaidLink(postPlaidLinkRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postPlaidLink']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * When performing recurring payments use this operation to trade user supplied payment information for a token which can be used to perform automated payments.
     * @summary Create a token for a recurring payment.
     * @param {PostTokenRequest} [postTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postToken(postTokenRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postToken(postTokenRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postToken']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     * Create a transaction for payment, capture, refund, or void.
     * @summary Create a transaction for payment, capture, refund, or void.
     * @param {CreateTransactionRequest} [createTransactionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTransaction(createTransactionRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postTransaction(createTransactionRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.postTransaction']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Update app configuration for the current app client
     * @param {AppConfig} appConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAppConfig(appConfig, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.putAppConfig(appConfig, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PayApi.putAppConfig']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * PayApi - factory interface
 * @export
 */
export const PayApiFactory = function (configuration, basePath, axios) {
  const localVarFp = PayApiFp(configuration);
  return {
    /**
     *
     * @summary Unsubscribe from this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMerchantSubscription(merchantId, options) {
      return localVarFp.deleteMerchantSubscription(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice(merchantId, deviceId, options) {
      return localVarFp.deletePaymentDevice(merchantId, deviceId, options).then(request => request(axios, basePath));
    },
    /**
     * Clients can cancel an approved transaction. This must be done before the batch closes which would then mark the transaction as captured. Note, the transaction is never actually deleted, its moved into the cancel state.
     * @summary Cancel an approved transaction
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransaction(transactionId, options) {
      return localVarFp.deleteTransaction(transactionId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return app configuration for the current app client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppConfig(options) {
      return localVarFp.getAppConfig(options).then(request => request(axios, basePath));
    },
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions(batchId, page, options) {
      return localVarFp.getBatchTransactions(batchId, page, options).then(request => request(axios, basePath));
    },
    /**
     * Disbursements
     * @summary Disbursements
     * @param {string} [merchantId]
     * @param {string} [transactionId]
     * @param {number} [isFee]
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page] page number (1-based)
     * @param {number} [limit] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options) {
      return localVarFp.getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options).then(request => request(axios, basePath));
    },
    /**
     * Get fee
     * @summary Get fee
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFee(id, options) {
      return localVarFp.getFee(id, options).then(request => request(axios, basePath));
    },
    /**
     * Search fees
     * @summary Search fees
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFees(page, limit, options) {
      return localVarFp.getFees(page, limit, options).then(request => request(axios, basePath));
    },
    /**
     * Get merchant object
     * @summary Get merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchant(merchantId, options) {
      return localVarFp.getMerchant(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     * Get merchants
     * @summary Get merchants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMerchants(options) {
      return localVarFp.getMerchants(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice(merchantId, deviceId, options) {
      return localVarFp.getPaymentDevice(merchantId, deviceId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices(merchantId, options) {
      return localVarFp.getPaymentDevices(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     * Heartbeat check for payrix
     * @summary Heartbeat check for payrix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayrixHeartbeat(options) {
      return localVarFp.getPayrixHeartbeat(options).then(request => request(axios, basePath));
    },
    /**
     * Get transaction by transaction ID
     * @summary Get transaction
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransaction(transactionId, options) {
      return localVarFp.getTransaction(transactionId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Upload a document
     * @param {string} documentId
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDocument(documentId, file, options) {
      return localVarFp.postDocument(documentId, file, options).then(request => request(axios, basePath));
    },
    /**
     * Create fee
     * @summary Create fee
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFee(body, options) {
      return localVarFp.postFee(body, options).then(request => request(axios, basePath));
    },
    /**
     * Create a transaction for an invoice.
     * @summary Create a transaction for an invoice
     * @param {PostGuestCheckoutInvoice} [postGuestCheckoutInvoice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGuestCheckoutInvoice(postGuestCheckoutInvoice, options) {
      return localVarFp.postGuestCheckoutInvoice(postGuestCheckoutInvoice, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create a merchant
     * @param {PostMerchantRequest} postMerchantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchant(postMerchantRequest, options) {
      return localVarFp.postMerchant(postMerchantRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Subscribe to this merchant for callback events
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMerchantSubscription(merchantId, options) {
      return localVarFp.postMerchantSubscription(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
      return localVarFp.postPaymentDevice(merchantId, postPaymentDeviceRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
      return localVarFp.postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {PostPlaidLinkRequest} postPlaidLinkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlaidLink(postPlaidLinkRequest, options) {
      return localVarFp.postPlaidLink(postPlaidLinkRequest, options).then(request => request(axios, basePath));
    },
    /**
     * When performing recurring payments use this operation to trade user supplied payment information for a token which can be used to perform automated payments.
     * @summary Create a token for a recurring payment.
     * @param {PostTokenRequest} [postTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postToken(postTokenRequest, options) {
      return localVarFp.postToken(postTokenRequest, options).then(request => request(axios, basePath));
    },
    /**
     * Create a transaction for payment, capture, refund, or void.
     * @summary Create a transaction for payment, capture, refund, or void.
     * @param {CreateTransactionRequest} [createTransactionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTransaction(createTransactionRequest, options) {
      return localVarFp.postTransaction(createTransactionRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update app configuration for the current app client
     * @param {AppConfig} appConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAppConfig(appConfig, options) {
      return localVarFp.putAppConfig(appConfig, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * PayApi - object-oriented interface
 * @export
 * @class PayApi
 * @extends {BaseAPI}
 */
export class PayApi extends BaseAPI {
  /**
   *
   * @summary Unsubscribe from this merchant for callback events
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  deleteMerchantSubscription(merchantId, options) {
    return PayApiFp(this.configuration).deleteMerchantSubscription(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Deregister a payment device for the given merchant
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  deletePaymentDevice(merchantId, deviceId, options) {
    return PayApiFp(this.configuration).deletePaymentDevice(merchantId, deviceId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Clients can cancel an approved transaction. This must be done before the batch closes which would then mark the transaction as captured. Note, the transaction is never actually deleted, its moved into the cancel state.
   * @summary Cancel an approved transaction
   * @param {string} transactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  deleteTransaction(transactionId, options) {
    return PayApiFp(this.configuration).deleteTransaction(transactionId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Return app configuration for the current app client
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getAppConfig(options) {
    return PayApiFp(this.configuration).getAppConfig(options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
   * @summary Get transactions for a given batchId
   * @param {string} batchId
   * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getBatchTransactions(batchId, page, options) {
    return PayApiFp(this.configuration).getBatchTransactions(batchId, page, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Disbursements
   * @summary Disbursements
   * @param {string} [merchantId]
   * @param {string} [transactionId]
   * @param {number} [isFee]
   * @param {string} [from]
   * @param {string} [to]
   * @param {number} [page] page number (1-based)
   * @param {number} [limit] number of items per page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options) {
    return PayApiFp(this.configuration).getDisbursements(merchantId, transactionId, isFee, from, to, page, limit, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Get fee
   * @summary Get fee
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getFee(id, options) {
    return PayApiFp(this.configuration).getFee(id, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Search fees
   * @summary Search fees
   * @param {number} [page]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getFees(page, limit, options) {
    return PayApiFp(this.configuration).getFees(page, limit, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Get merchant object
   * @summary Get merchant
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getMerchant(merchantId, options) {
    return PayApiFp(this.configuration).getMerchant(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Get merchants
   * @summary Get merchants
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getMerchants(options) {
    return PayApiFp(this.configuration).getMerchants(options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get payment device information
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getPaymentDevice(merchantId, deviceId, options) {
    return PayApiFp(this.configuration).getPaymentDevice(merchantId, deviceId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary List all active payment devices for the given merchant
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getPaymentDevices(merchantId, options) {
    return PayApiFp(this.configuration).getPaymentDevices(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Heartbeat check for payrix
   * @summary Heartbeat check for payrix
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getPayrixHeartbeat(options) {
    return PayApiFp(this.configuration).getPayrixHeartbeat(options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Get transaction by transaction ID
   * @summary Get transaction
   * @param {string} transactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  getTransaction(transactionId, options) {
    return PayApiFp(this.configuration).getTransaction(transactionId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Upload a document
   * @param {string} documentId
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postDocument(documentId, file, options) {
    return PayApiFp(this.configuration).postDocument(documentId, file, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Create fee
   * @summary Create fee
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postFee(body, options) {
    return PayApiFp(this.configuration).postFee(body, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Create a transaction for an invoice.
   * @summary Create a transaction for an invoice
   * @param {PostGuestCheckoutInvoice} [postGuestCheckoutInvoice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postGuestCheckoutInvoice(postGuestCheckoutInvoice, options) {
    return PayApiFp(this.configuration).postGuestCheckoutInvoice(postGuestCheckoutInvoice, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Create a merchant
   * @param {PostMerchantRequest} postMerchantRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postMerchant(postMerchantRequest, options) {
    return PayApiFp(this.configuration).postMerchant(postMerchantRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Subscribe to this merchant for callback events
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postMerchantSubscription(merchantId, options) {
    return PayApiFp(this.configuration).postMerchantSubscription(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Register a payment device for the given merchant
   * @param {string} merchantId
   * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
    return PayApiFp(this.configuration).postPaymentDevice(merchantId, postPaymentDeviceRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Post sale transaction via the given payment device
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
    return PayApiFp(this.configuration).postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {PostPlaidLinkRequest} postPlaidLinkRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postPlaidLink(postPlaidLinkRequest, options) {
    return PayApiFp(this.configuration).postPlaidLink(postPlaidLinkRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * When performing recurring payments use this operation to trade user supplied payment information for a token which can be used to perform automated payments.
   * @summary Create a token for a recurring payment.
   * @param {PostTokenRequest} [postTokenRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postToken(postTokenRequest, options) {
    return PayApiFp(this.configuration).postToken(postTokenRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   * Create a transaction for payment, capture, refund, or void.
   * @summary Create a transaction for payment, capture, refund, or void.
   * @param {CreateTransactionRequest} [createTransactionRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  postTransaction(createTransactionRequest, options) {
    return PayApiFp(this.configuration).postTransaction(createTransactionRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update app configuration for the current app client
   * @param {AppConfig} appConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayApi
   */
  putAppConfig(appConfig, options) {
    return PayApiFp(this.configuration).putAppConfig(appConfig, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * PaymentDeviceApi - axios parameter creator
 * @export
 */
export const PaymentDeviceApiAxiosParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice: (merchantId, deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('deletePaymentDevice', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('deletePaymentDevice', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'DELETE'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice: (merchantId, deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('getPaymentDevice', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('getPaymentDevice', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices: (merchantId, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('getPaymentDevices', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice: (merchantId, postPaymentDeviceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('postPaymentDevice', 'merchantId', merchantId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postPaymentDeviceRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }),
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale: (merchantId, deviceId, postPaymentDeviceSaleRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'merchantId' is not null or undefined
      assertParamExists('postPaymentDeviceSale', 'merchantId', merchantId);
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('postPaymentDeviceSale', 'deviceId', deviceId);
      const localVarPath = `/merchants/{merchantId}/paymentDevices/{deviceId}/sale`.replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'POST'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["write:api"], configuration);
      localVarHeaderParameter['Content-Type'] = 'application/json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      localVarRequestOptions.data = serializeDataIfNeeded(postPaymentDeviceSaleRequest, localVarRequestOptions, configuration);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * PaymentDeviceApi - functional programming interface
 * @export
 */
export const PaymentDeviceApiFp = function (configuration) {
  const localVarAxiosParamCreator = PaymentDeviceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice(merchantId, deviceId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePaymentDevice(merchantId, deviceId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PaymentDeviceApi.deletePaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice(merchantId, deviceId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getPaymentDevice(merchantId, deviceId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PaymentDeviceApi.getPaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices(merchantId, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getPaymentDevices(merchantId, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PaymentDeviceApi.getPaymentDevices']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPaymentDevice(merchantId, postPaymentDeviceRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PaymentDeviceApi.postPaymentDevice']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    },
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PaymentDeviceApi.postPaymentDeviceSale']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * PaymentDeviceApi - factory interface
 * @export
 */
export const PaymentDeviceApiFactory = function (configuration, basePath, axios) {
  const localVarFp = PaymentDeviceApiFp(configuration);
  return {
    /**
     *
     * @summary Deregister a payment device for the given merchant
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaymentDevice(merchantId, deviceId, options) {
      return localVarFp.deletePaymentDevice(merchantId, deviceId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get payment device information
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevice(merchantId, deviceId, options) {
      return localVarFp.getPaymentDevice(merchantId, deviceId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List all active payment devices for the given merchant
     * @param {string} merchantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDevices(merchantId, options) {
      return localVarFp.getPaymentDevices(merchantId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Register a payment device for the given merchant
     * @param {string} merchantId
     * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
      return localVarFp.postPaymentDevice(merchantId, postPaymentDeviceRequest, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Post sale transaction via the given payment device
     * @param {string} merchantId
     * @param {string} deviceId
     * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
      return localVarFp.postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * PaymentDeviceApi - object-oriented interface
 * @export
 * @class PaymentDeviceApi
 * @extends {BaseAPI}
 */
export class PaymentDeviceApi extends BaseAPI {
  /**
   *
   * @summary Deregister a payment device for the given merchant
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentDeviceApi
   */
  deletePaymentDevice(merchantId, deviceId, options) {
    return PaymentDeviceApiFp(this.configuration).deletePaymentDevice(merchantId, deviceId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get payment device information
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentDeviceApi
   */
  getPaymentDevice(merchantId, deviceId, options) {
    return PaymentDeviceApiFp(this.configuration).getPaymentDevice(merchantId, deviceId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary List all active payment devices for the given merchant
   * @param {string} merchantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentDeviceApi
   */
  getPaymentDevices(merchantId, options) {
    return PaymentDeviceApiFp(this.configuration).getPaymentDevices(merchantId, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Register a payment device for the given merchant
   * @param {string} merchantId
   * @param {PostPaymentDeviceRequest} [postPaymentDeviceRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentDeviceApi
   */
  postPaymentDevice(merchantId, postPaymentDeviceRequest, options) {
    return PaymentDeviceApiFp(this.configuration).postPaymentDevice(merchantId, postPaymentDeviceRequest, options).then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Post sale transaction via the given payment device
   * @param {string} merchantId
   * @param {string} deviceId
   * @param {PostPaymentDeviceSaleRequest} [postPaymentDeviceSaleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentDeviceApi
   */
  postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options) {
    return PaymentDeviceApiFp(this.configuration).postPaymentDeviceSale(merchantId, deviceId, postPaymentDeviceSaleRequest, options).then(request => request(this.axios, this.basePath));
  }
}
/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration) {
  return {
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions: (batchId, page, options = {}) => __awaiter(this, void 0, void 0, function* () {
      // verify required parameter 'batchId' is not null or undefined
      assertParamExists('getBatchTransactions', 'batchId', batchId);
      const localVarPath = `/batches/{batchId}/transactions`.replace(`{${"batchId"}}`, encodeURIComponent(String(batchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = Object.assign(Object.assign({
        method: 'GET'
      }, baseOptions), options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      // authentication OAuth2 required
      // oauth required
      yield setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:api"], configuration);
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    })
  };
};
/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function (configuration) {
  const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions(batchId, page, options) {
      var _a, _b, _c;
      return __awaiter(this, void 0, void 0, function* () {
        const localVarAxiosArgs = yield localVarAxiosParamCreator.getBatchTransactions(batchId, page, options);
        const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
        const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TransactionsApi.getBatchTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
        return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
      });
    }
  };
};
/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration, basePath, axios) {
  const localVarFp = TransactionsApiFp(configuration);
  return {
    /**
     * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
     * @summary Get transactions for a given batchId
     * @param {string} batchId
     * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchTransactions(batchId, page, options) {
      return localVarFp.getBatchTransactions(batchId, page, options).then(request => request(axios, basePath));
    }
  };
};
/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
  /**
   * Returns paged results listing all transactions for a given batchId. Typically, this is used by nightly processes to update transaction states for batches that have finalized.
   * @summary Get transactions for a given batchId
   * @param {string} batchId
   * @param {number} [page] page number (1-based) if omitted then page 1 is assumed.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  getBatchTransactions(batchId, page, options) {
    return TransactionsApiFp(this.configuration).getBatchTransactions(batchId, page, options).then(request => request(this.axios, this.basePath));
  }
}