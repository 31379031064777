/* tslint:disable */
/* eslint-disable */
/**
 * Payments API
 * # Introduction Manages onboarding of vendors and processing of payments  # Authentication The Payments API uses the OAuth2 client credentials grant to authorize service-to-service requests on the backend.  The caller should have only one application client that requests tokens for different audiences. Each API or audience that app client wishes to request tokens for requires an authorization in shared services IaC for Auth0.  If your application has not received Auth0 client credentials please refer to Architecture  Scopes are configured on client setup and all tokens requested by the client will have all scopes the client is entitled to.  The Token URL will change per environment.  - Dev - `https://dev.api.linqengineering.com/accounts/oauth/token`  - UAT - `https://uat.api.linqengineering.com/accounts/oauth/token`  - Production - `https://api.linqengineering.com/accounts/oauth/token`  In addition to your client credentials you also need to supply an audience value based on the environment. <br /> Valid Audiences include:      - Dev - `https://dev.api.linqengineering.com/payments`    - UAT - `https://uat.api.linqengineering.com/payments`    - Production - `https://api.linqengineering.com/payments`  Example Postman request to accounts API token endpoint:  ``` POST https://{{apiDomain}}.linqengineering.com/accounts/oauth/token ... raw -> JSON Body  {   \"clientId\": \"{{clientId}}\",   \"clientSecret\": \"{{clientSecret}}\",   \"audience\": \"https://{{apiDomain}}.linqengineering.com/payments\" } ```  See the Shared Services Postman collection for additional working examples. The returned access token should be added as a `Bearer` token authorization header.  API requests without an authorization header will fail.   <SecurityDefinitions />
 *
 * The version of the OpenAPI document: 0.0.33
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { RequiredError } from "./base";
/**
 *
 * @export
 */
export const DUMMY_BASE_URL = 'https://example.com';
/**
 *
 * @throws {RequiredError}
 * @export
 */
export const assertParamExists = function (functionName, paramName, paramValue) {
  if (paramValue === null || paramValue === undefined) {
    throw new RequiredError(paramName, `Required parameter ${paramName} was null or undefined when calling ${functionName}.`);
  }
};
/**
 *
 * @export
 */
export const setApiKeyToObject = function (object, keyParamName, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.apiKey) {
      const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? yield configuration.apiKey(keyParamName) : yield configuration.apiKey;
      object[keyParamName] = localVarApiKeyValue;
    }
  });
};
/**
 *
 * @export
 */
export const setBasicAuthToObject = function (object, configuration) {
  if (configuration && (configuration.username || configuration.password)) {
    object["auth"] = {
      username: configuration.username,
      password: configuration.password
    };
  }
};
/**
 *
 * @export
 */
export const setBearerAuthToObject = function (object, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.accessToken) {
      const accessToken = typeof configuration.accessToken === 'function' ? yield configuration.accessToken() : yield configuration.accessToken;
      object["Authorization"] = "Bearer " + accessToken;
    }
  });
};
/**
 *
 * @export
 */
export const setOAuthToObject = function (object, name, scopes, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.accessToken) {
      const localVarAccessTokenValue = typeof configuration.accessToken === 'function' ? yield configuration.accessToken(name, scopes) : yield configuration.accessToken;
      object["Authorization"] = "Bearer " + localVarAccessTokenValue;
    }
  });
};
function setFlattenedQueryParams(urlSearchParams, parameter, key = "") {
  if (parameter == null) return;
  if (typeof parameter === "object") {
    if (Array.isArray(parameter)) {
      parameter.forEach(item => setFlattenedQueryParams(urlSearchParams, item, key));
    } else {
      Object.keys(parameter).forEach(currentKey => setFlattenedQueryParams(urlSearchParams, parameter[currentKey], `${key}${key !== '' ? '.' : ''}${currentKey}`));
    }
  } else {
    if (urlSearchParams.has(key)) {
      urlSearchParams.append(key, parameter);
    } else {
      urlSearchParams.set(key, parameter);
    }
  }
}
/**
 *
 * @export
 */
export const setSearchParams = function (url, ...objects) {
  const searchParams = new URLSearchParams(url.search);
  setFlattenedQueryParams(searchParams, objects);
  url.search = searchParams.toString();
};
/**
 *
 * @export
 */
export const serializeDataIfNeeded = function (value, requestOptions, configuration) {
  const nonString = typeof value !== 'string';
  const needsSerialization = nonString && configuration && configuration.isJsonMime ? configuration.isJsonMime(requestOptions.headers['Content-Type']) : nonString;
  return needsSerialization ? JSON.stringify(value !== undefined ? value : {}) : value || "";
};
/**
 *
 * @export
 */
export const toPathString = function (url) {
  return url.pathname + url.search + url.hash;
};
/**
 *
 * @export
 */
export const createRequestFunction = function (axiosArgs, globalAxios, BASE_PATH, configuration) {
  return (axios = globalAxios, basePath = BASE_PATH) => {
    var _a;
    const axiosRequestArgs = Object.assign(Object.assign({}, axiosArgs.options), {
      url: (axios.defaults.baseURL ? '' : (_a = configuration === null || configuration === void 0 ? void 0 : configuration.basePath) !== null && _a !== void 0 ? _a : basePath) + axiosArgs.url
    });
    return axios.request(axiosRequestArgs);
  };
};