"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clone = void 0;
function clone(originalObject) {
  if (!originalObject) {
    return null;
  }
  return JSON.parse(JSON.stringify(originalObject));
}
exports.clone = clone;