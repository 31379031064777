"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.expirationMonth = void 0;
function verification(isValid, isPotentiallyValid, isValidForThisYear) {
  return {
    isValid: isValid,
    isPotentiallyValid: isPotentiallyValid,
    isValidForThisYear: isValidForThisYear || false
  };
}
function expirationMonth(value) {
  var currentMonth = new Date().getMonth() + 1;
  if (typeof value !== "string") {
    return verification(false, false);
  }
  if (value.replace(/\s/g, "") === "" || value === "0") {
    return verification(false, true);
  }
  if (!/^\d*$/.test(value)) {
    return verification(false, false);
  }
  var month = parseInt(value, 10);
  if (isNaN(Number(value))) {
    return verification(false, false);
  }
  var result = month > 0 && month < 13;
  return verification(result, result, result && month >= currentMonth);
}
exports.expirationMonth = expirationMonth;